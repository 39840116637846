<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="cardListQueryDetail">
      <div class="rightPerson_text">权益人信息</div>
      <div class="border"></div>
      <div class="rightPerson_content">
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                姓名：<span>{{ userInfo.name }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                手机号码：<span>{{ userInfo.mobile }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                证件号码：<span>{{ userInfo.certificateNo }}</span>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="rightPerson_content_table">
        <el-table :data="userContactList" border style="width: 100%">
          <el-table-column
            prop="name"
            label="使用人姓名"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="certificateNo"
            label="证件号码"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="relation"
            label="人员关系"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              {{ relationHandler(scope.row.relation) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="添加时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="cardListQueryDetail">
      <div class="rightPerson_text">
        <div class="rightPerson_FAQ">
          <div>权益卡信息</div>
          <div @click="goPackageFaq()">
            <img src="../../../assets/img/FAQ.png" alt="" />
            <span class="FAQ">查看产品FAQ</span>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="rightPerson_content">
        <el-row :gutter="20">
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                卡号：<span>{{ cardInfo.id }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                卡名称：<span>{{ cardInfo.packageName }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="10"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                激活有效期：<span
                  >{{ cardInfo.activateStartDate }}~{{
                    cardInfo.activateEndDate
                  }}</span
                >
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="rightPerson_content">
        <el-row :gutter="20">
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                激活状态：
                <span>{{
                  cardInfo.status == '0'
                    ? '未激活'
                    : cardInfo.status == '1'
                    ? '已激活'
                    : ''
                }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                激活日期：<span>{{ cardInfo.activateDate }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="10"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                有效期：<span
                  >{{ cardInfo.startDate }}~{{ cardInfo.endDate }}</span
                >
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="rightPerson_content_table">
        <el-table :data="userRightList" border style="width: 100%">
          <el-table-column
            prop="rightName"
            label="卡权益名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="rightDesc"
            label="权益描述"
            show-overflow-tooltip
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <div v-html="scope.row.rightDesc"></div>
            </template>
          </el-table-column>
          <el-table-column
            prop="waitPeriod"
            label="等待期（天）"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="totalAvailableCount"
            label="总次数"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="availableCount"
            label="可用次数"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column prop="availableCount" label="锁定中次数" header-align="center" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalAvailableCount - scope.row.availableCount }}
            </template>
          </el-table-column> -->
          <el-table-column
            :label="$t('handle')"
            fixed="right"
            header-align="center"
            align="center"
            width="250"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addServe(scope.row)"
                >新建服务单</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="cardListQueryDetail" v-if="showPolicy">
      <div class="rightPerson_text">关联保单信息</div>
      <div class="border"></div>
      <div class="rightPerson_content">
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                保单号：<span>{{ policyEntity.policyNo }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                产品名称：<span>{{ policyEntity.productName }}</span>
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="rightPersonName">
                保险期限：<span
                  >{{ policyEntity.startDate }}~{{ policyEntity.endDate }}</span
                >
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="cardListQueryDetail">
      <div class="rightPerson_text serveClass">服务单信息</div>
      <div class="rightPerson_content_table">
        <el-table :data="ticketsList" border style="width: 100%">
          <el-table-column
            prop="id"
            label="ID"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="rightName"
            label="权益名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="ticketsCode"
            label="服务单号"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="创建时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="服务状态"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 1">
                {{ $getDictLabel('service_order_status', scope.row.status) }}
              </el-tag>
              <el-tag v-if="scope.row.status === 2" type="info">
                {{ $getDictLabel('service_order_status', scope.row.status) }}
              </el-tag>
              <el-tag v-if="scope.row.status === 0" type="danger">
                {{ $getDictLabel('service_order_status', scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateDate"
            label="更新时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :label="$t('handle')"
            fixed="right"
            header-align="center"
            align="center"
            width="250"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="showDetailFuc(scope.row)"
                >处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      userInfo: {},
      userContactList: [],
      cardInfo: {},
      ticketsList: [],
      userRightList: [],
      policyEntity: {},
      showPolicy: false,
      packageFAQUrl: '',
      relationOptions: [
        {
          value: '1',
          label: '本人',
        },
        {
          value: '2',
          label: '父母',
        },
        {
          value: '3',
          label: '子女',
        },
        {
          value: '4',
          label: '配偶',
        },
        {
          value: '5',
          label: '雇佣',
        },
        {
          value: '6',
          label: '客户',
        },
        {
          value: '9',
          label: '其他',
        },
      ],
    };
  },
  methods: {
    queryDetail() {
      this.$http
        .get('/health/card/onceQueryCardInfo', {
          params: { cardId: this.$route.query.id },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code !== 0) {
            return this.$message.error(res.msg);
          }
          // 详情数据源
          let data = res.data.data;
          // 1、权益卡本人信息
          this.userInfo = {
            ...data.userEntity,
          };
          this.packageFAQUrl = data.packageFAQUrl;
          // 2、权益卡关联人列表
          this.userContactList = [...data.cardContactsList];
          // 3、权益卡信息
          this.cardInfo = { ...data };
          // 4、卡权益列表
          this.userRightList = [...data.userRightList];
          // 5、服务单列表
          this.ticketsList = [...data.ticketsList];
          // 6、判断是否有关联保单
          let policyEntity = data.policyEntity;
          if (!policyEntity) {
            this.showPolicy = false;
          } else {
            this.showPolicy = true;
            this.policyEntity = { ...policyEntity };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDetailFuc(row) {
      console.log(row);
      switch (row.rightId) {
        // 绿通类型
        case '1': //住院绿通
        case '2': //门诊绿通
        case '3': // 专属护理（bigtree）
        case '12': // 就医陪伴
        case '17': // 检查绿通
        case '18': // 手术绿通
        case '19': // 无等候就诊
        case '29': // 住院/手术绿通
        case '30': // 重疾门诊绿通
        case '31': // 癌症门诊绿通
        case '32': // 重疾手术/住院加快绿通（含陪诊）
        case '33': //  癌症手术/住院加快绿通（含陪诊）
        case '34': //   // 就医陪伴（  含产科）
        case '52': //   // 上门护理
          this.$router.push({
            path: '/healthticketsDetails',
            query: {
              id: row.id,
            },
          });
          break;
        case '4': // 住院垫付
        case '5': // 出院垫付
          this.$router.push({
            path: '/advanceDetails',
            query: {
              id: row.id,
            },
          });
          break;

        case '28': // 预约挂号（微医）
        case '47': //复诊预约挂号
        case '48': //肿瘤基因筛查
        case '49': //质子重离子绿通
          this.$router.push({
            path: '/greenHospitaltwoDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '35': // 定期随访
        case '36': // 定期随访
        case '37': // 定期随访
        case '38': // 定期随访
          this.$router.push({
            path: '/controlAndConsultationDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '10': // 重疾二次诊断
        case '23': // MDT多学科会诊
        case '24': // 医疗报告解读
        case '39': // 视频健康会诊
        case '40': // 癌症国内二诊
        case '46': // 普通二诊
          this.$router.push({
            path: '/seriousillnessDetail',
            query: {
              id: row.id,
            },
          });
          break;

        case '11': // 协助办理出院
          this.$router.push({
            path: '/assistLeaveHospitalDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '26': // 海外就医协助
        case '41': // 海外二诊及海外就医协助
        case '42': // 海外二次诊疗
        case '43': // 癌症海外二诊
        case '44': // 重疾海外二诊
          this.$router.push({
            path: '/OverseasClassDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '20': // 中医上门理疗
          this.$router.push({
            path: '/chineseMedicineDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '58': //
        case '59': //
        case '60': //
        case '61': //
        case '62': //
        case '63': //
        case '64': //
        case '65': //
          this.$router.push({
            path: '/exclusiveCareDetails',
            query: {
              id: row.id,
            },
          });
          break;
        case '71': //
          this.$router.push({
            path: '/geneticTestingDetails',
            query: {
              id: row.id,
            },
          });
          break;
      }
    },
    addDate(date, days) {
      var d = new Date(date);
      d.setDate(d.getDate() + days);
      var m = d.getMonth() + 1;
      return d.getFullYear() + '-' + m + '-' + d.getDate();
    },
    // 新建服务单
    addServe(row) {
      // console.log("当前等待期", this.addDate("2021-09-30",5));
      // 获取当前时间戳
      let newDate = Date.parse(new Date());
      // 时间戳转换
      let sDate = Date.parse(this.cardInfo.startDate);
      let eDate = Date.parse(this.cardInfo.endDate);
      // 获取权益使用人列表
      let uCList = this.userContactList;
      let WaitingDate = this.addDate(this.cardInfo.startDate, row.waitPeriod);
      let eWaitPeriod = Date.parse(WaitingDate);
      switch (row.rightId) {
        case '6':
        case '7':
        case '8':
        case '9':
        case '14':
        case '15':
        case '16':
        case '21':
        case '22':
        case '27':
        case '45':
        case '50':
        case '53':
        case '54':
        case '55':
          this.$message.error('该权益不是服务单类型');
          break;
        default:
          if (uCList.length === 0) {
            this.$message.error('该权益暂不可用，请先绑定权益使用人');
          } else {
            if (sDate < newDate && newDate < eDate) {
              if (newDate > eWaitPeriod) {
                if (row.availableCount !== 0) {
                  this.$router.push({
                    path: '/addServiceTickets',
                    query: {
                      id: row.id,
                    },
                  });
                } else {
                  this.$message.error('该权益暂不可用，可用次数为0');
                }
              } else {
                this.$message.error('该权益暂不可用，还在等待期内');
              }
            } else {
              this.$message.error('该权益暂不可用，不在有效期内');
            }
          }
      }
      // this.$router.push({
      //     path: "/addServiceTickets",
      //     query: {
      //       id: row.id,
      //     },
      //   });
    },
    goPackageFaq() {
      let packageFAQUrl = this.packageFAQUrl;
      if (!packageFAQUrl) {
        return this.$message.error('当前产品暂无FAQ');
      } else {
        window.open(packageFAQUrl, '_blank');
      }
    },
    relationHandler(data) {
      const demo = this.relationOptions.filter((i) => i.value === String(data));
      return (demo.length && demo[0].label) || '';
    },
  },
  components: {},
  created() {
    this.queryDetail();
  },
  // created() {

  // },
};
</script>
<style lang="scss" scoped>
.cardListQueryDetail {
  margin-bottom: 40px;
  .serveClass {
    margin-bottom: 10px;
  }
  .rightPerson_text {
    // width: 86px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 22px;
    letter-spacing: 1px;
    .rightPerson_FAQ {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      .FAQ {
        // width: 79px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 17px;
      }
    }
  }
  .border {
    border-bottom: 1px solid #e6e3e3;
    margin: 10px 0px 20px 0px;
  }
  .rightPerson_content {
    margin-bottom: 10px;
    margin-top: 10px;
    // margin-left: -100px;
    .rightPersonName {
      // width: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      // text-align: right;

      span {
        // width: 45px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
<style>
.cardListQueryDetail {
}
.el-tooltip__popper {
  max-width: 30%;
  font-size: 14px;
}
.el-tooltip p {
  margin: 0;
}
</style>
